<template>
<div>
  <!--<h4 class="mb-10 font-weight-bold text-dark">-->
  <!--{{ $t('ORDERS.CLIENT.TITLE')}}-->
  <!--</h4>-->
  <b-container fluid class="p-0">
    <b-row>
      <b-col cols="12">
        <b-form-group id="select-group-1" :label="`${$t('FORM.CLIENT_SEARCH')}:`" label-for="select-1">
          <SelectInfinityScroll
            ref="selectClient"
            id="select-1"
            v-model="contact"
            :search.sync="searchContact"
            :params="paramsContact"
            api="company/client"
            @input="onSelect"
            clearable
          >
            <template #selected="{ selected }">
              <span class="font-weight-bold">
                <span v-if="selected.firstName">{{ selected.firstName }}</span>
                <span v-if="selected.lastName"> {{ selected.lastName }}</span>
              </span> ({{ selected.phone }})
            </template>
            <template #option="{ option }">
              <span class="font-weight-bold">
                <span v-if="option.firstName">{{ option.firstName }}</span>
                <span v-if="option.lastName"> {{ option.lastName }}</span>
              </span> ({{ option.phone }})
            </template>
            <template #isEmpty>
              <div class="text-center text-muted py-3">
                <span class="text-muted">{{ $t('BASE.NO_DATA') }}</span>
              </div>
              <div class="text-center py-3">
                <b-button class="btn btn-light-success btn-sm" @click="onDuplicateClient">
                  <span class="svg-icon btn-light-success">
                    <inline-svg src="/media/svg/icons/Files/File-plus.svg" />
                  </span>
                  {{ $t('ORDERS.CLIENT.CREATE_NEW_CLIENT') }}
                </b-button>
              </div>
            </template>
          </SelectInfinityScroll>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6">
        <b-form-group :label="`${$t('FORM.FIRST_NAME')}:`" label-for="input-1">
          <InputForm
            id="input-1"
            v-model="$v.form.firstName.$model"
            :state="validateState('firstName')"
            :placeholder="$t('PLACEHOLDER.FIRST_NAME')"
            @input="clearServerError('firstName')"
            aria-describedby="input-1-feedback"
          />
          <b-form-invalid-feedback id="input-1-feedback">
            <template v-if="serverErrors.firstName">{{ serverErrors.firstName[0] }}</template>
            <template v-else>{{ $t('VALIDATION.REQUIRED', { name: $t('FORM.FIRST_NAME') }) }}</template>
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group :label="`${$t('FORM.PHONE')}:`" label-for="input-2">
          <InputForm
            id="input-2"
            v-model="$v.form.phone.$model"
            :state="validateState('phone')"
            :placeholder="$t('PLACEHOLDER.PHONE')"
            @input="clearServerError('phone')"
            aria-describedby="input-2-feedback"
          />
          <b-form-invalid-feedback id="input-2-feedback">
            <template v-if="serverErrors.phone">{{ serverErrors.phone[0] }}</template>
            <template v-else-if="!$v.form.phone.alphaPhone">{{ $t('VALIDATION.INVALID', { name: $t('FORM.PHONE') }) }}</template>
            <template v-else>{{ $t('VALIDATION.REQUIRED', { name: $t('FORM.PHONE') }) }}</template>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6">
        <b-form-group :label="`${$t('FORM.LAST_NAME')}:`" label-for="input-3">
          <InputForm
            id="input-3"
            v-model="$v.form.lastName.$model"
            :state="validateState('lastName')"
            :placeholder="$t('PLACEHOLDER.LAST_NAME')"
            @input="clearServerError('LAST_NAME')"
            aria-describedby="input-3-feedback"
          />
          <b-form-invalid-feedback id="input-3-feedback">
            <template v-if="serverErrors.lastName">{{ serverErrors.lastName[0] }}</template>
            <template v-else>{{ $t('VALIDATION.REQUIRED', { name: $t('FORM.LAST_NAME') }) }}</template>
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group id="select-group-2" :label="`${$t('FORM.BRANCH')}:`" label-for="select-2">
          <SelectInfinityScroll
            v-model="$v.orderForm.branch.$model"
            :search.sync="branchService"
            :params="paramsBranch"
            :state="validateStateOrder('branch') === false"
            id="select-2"
            api="company/branches"
            aria-describedby="select-2-feedback"
            clearable
          />
          <b-form-invalid-feedback id="select-2-feedback" :class="{'d-block': validateStateOrder('branch') === false}">
            <template v-if="true">
              {{ $t('VALIDATION.REQUIRED', { name: $t('FORM.BRANCH') }) }}
            </template>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <div class="text-right">
          <div v-if="$v.form.$anyDirty" class="btn btn-secondary font-weight-bold mr-3 btn-sm" @click="resetForm">
            {{ $t('FORM.CLEAR') }}
          </div>
          <b-button :disabled="loading || $v.form.$anyError || $v.orderForm.$anyError" type="submit" variant="info" size="sm" @click="onSubmit">
            <b-spinner v-if="loading" small variant="light" />
            {{ $t('FORM.SAVE_AND_NEXT') }}
          </b-button>
        </div>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import { mapState } from 'vuex'
import { validationMixin } from 'vuelidate'
import { CREATE_ORDER, FETCH_ORDER, UPDATE_ORDER } from '@/core/services/store/order.module'
import { helpers, required } from 'vuelidate/lib/validators'
import Repo from '@/core/repository/company/clientsRepository'
import serverVuelidate from '../../../mixins/serverVuelidate'
import SelectInfinityScroll from '../../forms-items/SelectInfinityScroll'
import InputForm from '../../forms-items/input'
import { TEL } from '../../../helpers/validation'

export default {
  name: 'ClientOrder',
  components: {
    InputForm,
    SelectInfinityScroll, 
  },
  mixins: [validationMixin, serverVuelidate],
  data() {
    return {
      loading: false,
      searchContact: '',
      branchService: '',
      contact: null,
      form: {
        uuid: '',
        firstName: '',
        lastName: '',
        phone: '',
      },
      orderForm: {
        branch: null,
      },
      validations: {
        form: {
          firstName: { required },
          lastName: {},
          phone: {
            required,
            alphaPhone: helpers.regex('alpha', TEL), 
          },
        },
        orderForm: {
          branch: { required },
        },
      },
    }
  },
  computed: {
    ...mapState({
      order: (state) => state.order.item,
    }),
    canLoad() {
      return (query) => (!query.type || query.type === 'client') && query.client && !this.form.uuid
    },
    paramsBranch() {
      return {
        active: true,
      }
    },
    paramsContact() {
      return {
        search: this.searchContact,
      }
    },
  },
  watch: {
    order: {
      handler(item) {
        if (item.branch) this.orderForm.branch = item.branch
      },
      deep: true,
      immediate: true,
    },
    '$route.query': {
      handler(query) {
        if (this.canLoad(query)) {
          this.fetchData()
        }
      },
      deep: true,
      immediate: true,
    },
    'form.uuid': {
      handler(val) {
        if (val) return
        const { client, ...query } = this.$route.query
        this.$router.replace({ query })
      },
    },
  },
  methods: {
    onDuplicateClient() {
      const str = this.searchContact.slice()
      this.$refs.selectClient.onHide()
      if (/^[0-9]*$/.test(str)) {
        this.form.phone = str
        this.$v.form.phone.$touch()
      }
    },
    fetchData() {
      Repo.get(this.$route.query.client).then(({ data }) => {
        this.setItem(data.payload)
        this.contact = { ...data.payload }
        this.$v.$touch()
      })
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },
    validateStateOrder(name) {
      const { $dirty, $error } = this.$v.orderForm[name]
      return $dirty ? !$error : null
    },
    onSelect(item) {
      if (this.$route.query.order && item) {
        this.$store.dispatch(UPDATE_ORDER, {
          uuid: this.$route.query.order,
          client: item.uuid,
        })
      }
      // eslint-disable-next-line no-unused-expressions
      item ? this.setItem(item) : this.resetForm()
      this.$v.form.$touch()
    },
    resetForm() {
      this.form = {
        uuid: '',
        firstName: '',
        lastName: '',
        phone: '',
      }
      // this.orderForm = {
      //   branch: null
      // }
      this.$nextTick(() => {
        this.$v.$reset()
      })
    },
    setItem(item) {
      Object.keys(this.form).forEach((propName) => {
        if (
          this.form.hasOwnProperty(propName)
            && item.hasOwnProperty(propName)
        ) {
          if (item[propName] !== null && item[propName] !== undefined) {
            this.form[propName] = item[propName]
          }
        }
      })
    },
    onCreate(obj) {
      Repo.post(obj).then(({ data }) => {
        // this.$router.replace({query: {client: data.payload.uuid} })
        this.onCreateOrder(data.payload.uuid)
        this.loading = false
      }).catch((err) => {
        this.loading = false
        this.seServerErrors(err.response.data)
      })
    },
    onEdit(uuid, obj) {
      this.$router.replace({
        query: {
          ...this.$route.query,
          client: uuid,
          order: this.$route.query.order,
          type: 'setup', 
        }, 
      })
      Repo.patch(uuid, obj).then(() => {
        // this.$router.replace({query: {type: 'setup', client: uuid} })
        this.onCreateOrder(uuid)
        this.loading = false
      }).catch((err) => {
        this.loading = false
        this.seServerErrors(err.response.data)
      })
    },
    async onCreateOrder(client) {
      if (!this.$route.query.order) {
        const { data } = await this.$store.dispatch(CREATE_ORDER, {
          client,
          status: 'raw',
          branch: this.orderForm.branch.uuid || this.orderForm.branch,
        })
        await this.$store.dispatch(FETCH_ORDER, data.payload.uuid)
        this.$router.replace({
          query: {
            ...this.$route.query,
            client,
            order: data.payload.uuid,
            type: 'setup', 
          }, 
        })
      } else {
        this.$router.replace({
          query: {
            ...this.$route.query,
            type: 'setup', 
          }, 
        })
      }
    },
    onSubmit() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      const obj = { ...this.form }
      const { uuid, ...data } = obj

      this.clearServerErrors()
      this.loading = true

      // eslint-disable-next-line no-unused-expressions
      uuid ? this.onEdit(uuid, data) : this.onCreate(data)
    },
  },
}
</script>

<style scoped>

</style>
